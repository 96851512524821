::-webkit-input-placeholder {
  color: #525F7F;
}

:-moz-placeholder {
  color: #525F7F;
}

::-moz-placeholder {
  color: #525F7F;
}

:-ms-input-placeholder {
  color: #525F7F;
}

.scrollable {
  scrollbar-color: #DCE0E7 #f8fafc;
  scrollbar-width: thin;
}

.scrollable::-webkit-scrollbar {
  width: 0.3125rem;
  height: 0.3125rem;
  -moz-appearance: none !important;
}

.scrollable::-webkit-scrollbar-track, .scrollable::-webkit-scrollbar-track-piece {
  background-color: #f8fafc;
  -moz-appearance: none !important;
  border: none !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.scrollable::-webkit-scrollbar-thumb, .scrollable::-webkit-scrollbar-thumb:vertical, .scrollable::-webkit-scrollbar-thumb:horizontal {
  background-color: #DCE0E7;
  outline: none;
  border-radius: 0.25rem;
  -moz-appearance: none !important;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.m-10 {
  margin: 0.625rem;
}

.mx-30 {
  margin-left: 1.875rem;
  margin-right: 1.875rem;
}

.ml-30 {
  margin-left: 1.875rem;
}

.mr-30 {
  margin-right: 1.875rem;
}

.text-clr {
  color: #767676;
}

.theme-clr {
  color: #5E72E4;
}

.themedark-clr {
  color: #32325D;
}

.themetext-clr {
  color: #525F7F;
}

.dark-grey {
  color: #1E1E1E;
}

.green-clr {
  color: #83BA0D;
}

.red-clr {
  color: #EE6352;
}

.qblue-clr {
  color: #00AEEF;
}

.font-10 {
  font-size: 0.625rem;
  line-height: 1rem;
}

.xxs-font {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.font-13 {
  font-size: 0.8125rem !important;
  line-height: 1.25rem;
}

.xs-font {
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

.body-font {
  font-size: 1rem;
  line-height: 1.375rem;
}

.body-font2 {
  font-size: 1rem;
  line-height: 1.6875rem;
}

.sm-font {
  font-size: 1.25rem !important;
  line-height: 1.875rem !important;
}

.font-bold {
  font-weight: bold;
}

.font-regular {
  font-weight: normal;
}

.font-semibold {
  font-weight: 600;
}

.btn-gray {
  background-color: #767676;
  color: #FFFFFF;
}

.btn-gray:hover, .btn-gray:focus {
  color: #FFFFFF;
}

.btn-secondary {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn {
  padding: 0.532rem 1.25rem;
  line-height: 1.3125rem;
}

.btn:hover {
  -webkit-transform: none;
  transform: none;
}

.btn.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.btn.disabled, .btn:disabled {
  opacity: .3;
}

.au-btn {
  font-size: 1rem;
}

.au-btn.export-btn {
  min-height: 3.125rem;
}

.au-btn.export-btn .btn:not(:disabled):not(.disabled):active, .au-btn.export-btn .btn:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

@media (max-width: 767.98px) {
  .au-btn.export-btn {
    border-left: 0.0625rem solid #ddd;
    border-right: 0.0625rem solid #ddd;
    width: 3.125rem;
  }
  .au-btn.export-btn .btn {
    padding: 0;
  }
}

@media (max-width: 767.98px) {
  .more-btn {
    width: 3.125rem;
    height: 3.125rem;
    display: inline-block;
    padding: 0.625rem !important;
    text-align: center;
  }
}

.au-title {
  color: #5E72E4;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
  border-bottom: 0.1875rem solid #5E72E4;
  padding: 1.0625rem 1.25rem;
}

.min-h-50 {
  min-height: 3.125rem;
}

.dropdown-menu {
  -webkit-box-shadow: 0 0.125rem 0.625rem 0 rgba(136, 152, 170, 0.39);
  box-shadow: 0 0.125rem 0.625rem 0 rgba(136, 152, 170, 0.39);
  border-radius: 0.375rem;
}

.dropdown-menu .dropdown-item {
  padding: 0.875rem 1.25rem;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  color: #212529;
}

.audropdownmenu {
  min-width: 9rem;
  padding: 0;
}

.audropdownmenu.dropdown-menu-right {
  right: 0;
  left: auto;
}

.ic_ {
  height: 1.5rem;
  width: 1.5rem;
  fill: currentColor;
}

.ic_min {
  min-height: 1.5rem;
  min-width: 1.5rem;
}

.top-nav {
  padding: 0 1.875rem;
  height: 3.75rem;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #DDDDDD;
  box-shadow: inset 0 -0.0625rem 0 0 #DDDDDD;
}

@media (max-width: 767.98px) {
  .top-nav {
    padding: 0 0.9375rem;
  }
}

.navoptions {
  grid-gap: 0 !important;
}

.navoptions li a {
  padding: 1.0625rem 1.25rem;
  color: #767676;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
  border: 0;
  margin-bottom: 0;
  border-bottom: 0.1875rem solid transparent;
  display: block;
}

.navoptions li a:hover, .navoptions li a.active {
  margin-bottom: 0;
  color: #5E72E4;
  border-bottom: 0.1875rem solid #5E72E4;
}

@media (max-width: 767.98px) {
  .navoptions li a {
    padding: 1.0625rem 1.25rem;
    color: #767676;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.375rem;
    border: 0;
    margin-bottom: 0;
    border-bottom: 0.1875rem solid transparent;
  }
}

.bottom-nav {
  padding: 0 1.875rem;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #DDDDDD;
  box-shadow: inset 0 -0.0625rem 0 0 #DDDDDD;
}

@media (max-width: 767.98px) {
  .bottom-nav {
    padding: 0;
    position: relative;
    height: 3.125rem;
  }
}

@media screen and (max-width: 736px) and (orientation: landscape) {
  .navoptions li a {
    padding: 0.8125rem 1.25rem;
  }
}

.au-tab-content {
  padding: 1.4375rem 1.875rem;
  background: #f8f9fe;
}

@media (max-width: 767.98px) {
  .au-tab-content {
    padding: 1.25rem 0.625rem;
  }
  .au-tab-content > .tab-pane {
    padding-left: 0;
    padding-right: 0;
  }
  .au-tab-content > .tab-pane .row {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .au-tab-content > .tab-pane .row [class*="col-"] {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}

.au-tabs li .nav-link {
  padding: 0.75rem 1.25rem;
  color: #767676;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
  border: 0;
  margin-bottom: 0;
  border-bottom: 0.1875rem solid transparent;
}

.au-tabs li .nav-link:hover, .au-tabs li .nav-link.active {
  border: 0;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: #5E72E4;
  background-color: #fff;
  border-bottom: 0.1875rem solid #5E72E4;
}

@media (max-width: 991.98px) {
  .au-tabs li .nav-link {
    padding: 0.75rem 0.875rem;
  }
  .au-tabs li .nav-link:hover, .au-tabs li .nav-link.active {
    padding: 0.75rem 0.875rem;
  }
}

@media (max-width: 767.98px) {
  .au-tabs {
    position: absolute;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    top: 100%;
    z-index: 1;
    background-color: #fff;
    left: 0;
    right: 0;
    display: none;
  }
  .au-tabs.open {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .au-tabs li {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .mobile-bottom-nav {
    padding: 0.75rem 0.9375rem;
    font-size: 1rem;
    line-height: 1.625rem;
  }
}

.audit_dash .header.pb-6 {
  padding-bottom: 0 !important;
}

.audit_dash .header-body .py-4 {
  padding: 0 !important;
}

.top-nav-buttons {
  position: absolute;
  right: 1.875rem;
  top: 0.8125rem;
}

.top-nav-buttons .btn {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #fff;
  border: 0;
}

.top-nav-buttons .btn:hover {
  color: #525f7f;
}

.top-nav-buttons .btn:hover, .top-nav-buttons .btn:focus, .top-nav-buttons .btn:active {
  background: #fff !important;
  -webkit-transform: none;
  transform: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

@media (max-width: 767.98px) {
  .top-nav-buttons {
    right: 0.9375rem;
  }
}

/*.DatePicker {*/
/*  position: absolute;*/
/*  right: 5.25rem;*/
/*  top: -3rem;*/
/*}*/

.DatePicker .btn-date {
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-right: 0 !important;
}

.DatePicker .btn-date:hover, .DatePicker .btn-date:focus, .DatePicker .btn-date:active {
  background: #fff !important;
  -webkit-transform: none;
  transform: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

@media (max-width: 767.98px) {
  .DatePicker {
    right: 4rem;
  }
}

.topnav-toggler-line {
  width: 1.375rem;
  height: 0.125rem;
  background-color: #767676;
  -webkit-transition: .3s transform;
  transition: .3s transform;
  display: block;
}

.topnav-toggler-line:not(:last-child) {
  margin-bottom: 0.3125rem;
}

.topnav-toggler-inner {
  width: 1.375rem;
  height: 1rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.topnav-toggler-inner:before {
  content: none;
}

.close-topnav .topnav-toggler-line:last-child {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 1.1875rem 0;
          transform-origin: 1.1875rem 0;
}

.close-topnav .topnav-toggler-line:first-child {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 1.3125rem 0.1875rem;
          transform-origin: 1.3125rem 0.1875rem;
}

.close-topnav .topnav-toggler-line:nth-child(2) {
  opacity: 0;
}

.btn.btn-icon:hover, .btn.btn-icon:focus, .btn.btn-icon:active {
  background: transparent !important;
  -webkit-transform: none;
  transform: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.slide-pane.right-pane {
  -webkit-box-shadow: 0 0 0.625rem 0 #ddd;
  box-shadow: 0 0 0.625rem 0 #ddd;
}

.slide-pane.right-pane .slide-pane__header {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  background: #F8F9FE;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #DDDDDD;
  box-shadow: inset 0 -0.0625rem 0 0 #DDDDDD;
  padding: 0.875rem 1.875rem;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 3.125rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 3.125rem;
          flex: 0 0 3.125rem;
}

.slide-pane.right-pane .slide-pane__header .slide-pane__close {
  margin-left: 0;
  padding: 0;
  background: url(../img/auditui/Icons/ic_close.svg) no-repeat center;
  background-size: 1.5rem 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
}

.slide-pane.right-pane .slide-pane__header .slide-pane__close svg {
  display: none;
}

.slide-pane.right-pane .slide-pane__title {
  color: #32325D;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.375rem;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.slide-pane.right-pane .slide-pane__subtitle {
  margin-left: 0 !important;
  color: #767676;
}

.slide-pane.right-pane .slide-pane__title-wrapper {
  margin-right: 0;
  margin-left: 0;
}

.slide-pane.right-pane .slide-pane__content {
  padding: 0;
}

.slide-pane.right-pane .slide-pane__content .card-body {
  padding: 1.25rem 1.875rem;
}

.accordion .card-header {
  background-color: #f8f9fe;
}

.accordion .card-header:after {
  content: none;
}

.accordion .card-header .btn-link {
  padding: 0.875rem 3.75rem 0.875rem 1.875rem;
  background: #F8F9FE url(../img/auditui/Icons/expand_more.svg) no-repeat center right 1.875rem;
  -webkit-box-shadow: inset 0 0.0625rem 0 0 #DDDDDD, inset 0 -0.0625rem 0 0 #DDDDDD;
          box-shadow: inset 0 0.0625rem 0 0 #DDDDDD, inset 0 -0.0625rem 0 0 #DDDDDD;
}

.accordion .card-header .btn-link:not(.disabled):active:focus {
  -webkit-box-shadow: inset 0 0.0625rem 0 0 #DDDDDD, inset 0 -0.0625rem 0 0 #DDDDDD;
          box-shadow: inset 0 0.0625rem 0 0 #DDDDDD, inset 0 -0.0625rem 0 0 #DDDDDD;
}

.accordion .card-header[aria-expanded="true"] .btn-link {
  background: #F8F9FE url(../img/auditui/Icons/expand_less.svg) no-repeat center right 1.875rem;
}

.accordion .card-header.accord-symb .btn-link {
  background-image: none;
  cursor: default;
}

@media (max-width: 767.98px) {
  .accordion .card-header.lbcard-header {
    pointer-events: none;
  }
}

@media (max-width: 767.98px) {
  .accordion .card-header.lbcard-header .btn-link {
    background: #fff !important;
    padding: 0.625rem;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.accordion .card-header[aria-expanded="false"]:after {
  content: none;
}

.sidenav-toggler-inner {
  width: 1.375rem;
}

.btn.btn-icon:hover, .btn.btn-icon:focus, .btn.btn-icon:active {
  background: transparent !important;
  -webkit-transform: none;
  transform: none;
}

.btn.btn-icon:hover .btn-link, .btn.btn-icon:focus .btn-link, .btn.btn-icon:active .btn-link {
  background: #F8F9FE url(../img/auditui/Icons/expand_less.svg) no-repeat center right 1.875rem;
}

.btn.btn-icon:hover:after, .btn.btn-icon:focus:after, .btn.btn-icon:active:after {
  content: none;
}

.modal .modal-dialog.modal-right {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  right: 0;
}

.modal.fade {
  transition: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
}

.modal.fade.show {
  transition: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
}

.fade .modal-dialog.modal-right {
  -webkit-animation-name: none;
          animation-name: none;
  -webkit-transition: opacity 0.4s linear, right 0.4s ease-out;
  transition: opacity 0.4s linear, right 0.4s ease-out;
}

.modal .modal-dialog.modal-right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
  border: none;
}

.modal.fade .modal-dialog.modal-right {
  right: -100%;
  -webkit-transition: opacity 0.4s linear, right 0.4s ease-out;
  transition: opacity 0.4s linear, right 0.4s ease-out;
}

.modal.fade.show .modal-dialog.modal-right {
  right: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.modal .modal-right .modal-header {
  background-color: #F8F9FE;
  padding: 0.8125rem 1.875rem 0.8125rem;
}

.modal .modal-right .modal-header .close {
  margin: 0 !important;
}

.fade.show .modal-dialog {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
}

.modal-header .close {
  margin: 0;
  color: #1E1E1E;
  margin-top: -0.3125rem;
}

@media (min-width: 576px) {
  .modal-filter .modal-header, .modal-right-content .modal-header {
    width: 31.25rem;
    max-width: 31.25rem;
    margin-left: auto;
  }
}

@media (min-width: 576px) {
  .modal-filter .modal-content, .modal-right-content .modal-content {
    width: 31.25rem;
    max-width: 31.25rem;
    margin-left: auto;
  }
}

.modal-right .modal-content {
  scrollbar-color: #DCE0E7 #f8fafc;
  scrollbar-width: thin;
}

.modal-right .modal-content::-webkit-scrollbar {
  width: 0.3125rem;
  height: 0.3125rem;
  -moz-appearance: none !important;
}

.modal-right .modal-content::-webkit-scrollbar-track, .modal-right .modal-content::-webkit-scrollbar-track-piece {
  background-color: #f8fafc;
  -moz-appearance: none !important;
  border: none !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.modal-right .modal-content::-webkit-scrollbar-thumb, .modal-right .modal-content::-webkit-scrollbar-thumb:vertical, .modal-right .modal-content::-webkit-scrollbar-thumb:horizontal {
  background-color: #DCE0E7;
  outline: none;
  border-radius: 0.25rem;
  -moz-appearance: none !important;
}

@media (min-width: 768px) {
  .modal-ticket-detail-content .modal-header {
    width: 41.25rem;
    max-width: 41.25rem;
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .modal-ticket-detail-content .modal-content {
    width: 41.25rem;
    max-width: 41.25rem;
    margin-left: auto;
  }
}

.modal .modal-right .modal-header {
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #E9ECEF;
          box-shadow: inset 0 -0.0625rem 0 0 #E9ECEF;
  position: fixed;
  top: 0;
  right: -100%;
  width: 0;
  z-index: 1;
  border-radius: 0;
}

@media (max-width: 767.98px) {
  .modal .modal-right .modal-header {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.modal.show .modal-right .modal-header {
  width: 100%;
  right: 0;
  z-index: 2;
}

.modal .modal-right .modal-body {
  padding-top: 3.25rem !important;
}

.search-input {
  background: #fff url(../img/auditui/Icons/ic_search.svg) no-repeat left 1.875rem center;
  padding-left: 4.125rem;
  border-left: 0;
  border-right: 0;
  color: #525F7F;
  height: 3.25rem;
}

.radio-btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 1;
  border: 0.0625rem solid #95A4B2;
  border-radius: 0.375rem;
  overflow: hidden;
}

.radio-btn-wrapper > input {
  display: none;
}

.radio-btn-wrapper > input:checked + label {
  color: #fff;
}

.radio-btn-wrapper > input:nth-of-type(1):checked ~ label:last-of-type:before {
  -webkit-transform: translateX(calc(0% + 0px));
          transform: translateX(calc(0% + 0px));
  background: #5E72E4;
}

.radio-btn-wrapper > input:nth-of-type(2):checked ~ label:last-of-type:before {
  -webkit-transform: translateX(calc(100% + 0px));
          transform: translateX(calc(100% + 0px));
  background: #5E72E4;
}

.radio-btn-wrapper > input:first-child + label {
  border-left: none;
}

.radio-btn-wrapper label {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  margin: 0.1875rem 0;
  padding: 0.3125rem 0.625rem;
  -webkit-transition: color 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
  transition: color 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
  border-left: 0;
}

.radio-btn-wrapper > input:first-child + label {
  border-left: none;
}

.radio-btn-wrapper label:last-of-type:before {
  content: "";
  display: block;
  max-width: calc(503% + 0.0625em);
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.radio-btn-wrapper label:before {
  background: #fff;
  -webkit-transition: all 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
  transition: all 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
  border-radius: 0;
}

.two-opt.radio-btn-wrapper label:last-of-type:before {
  max-width: calc(50% - 0);
}

.radio-btn-wrapper > input:nth-of-type(2):checked + label {
  border-left: 0;
}

.radio-btn-wrapper.two-radio-wrapper label:last-of-type:before {
  max-width: calc(50% + 0.0625em);
}

.plant-radio input[type="radio"] {
  display: none;
}

.plant-radio label {
  color: #525F7F;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #DDDDDD;
  box-shadow: inset 0 -0.0625rem 0 0 #DDDDDD;
  display: block;
  padding: 0.875rem 1.875rem;
  margin-bottom: 0;
  cursor: pointer;
}

.plant-radio input[type="radio"]:checked ~ label {
  color: #5E72E4;
  font-weight: bold;
  padding-right: 4.125rem;
  background: rgba(94, 114, 228, 0.1) url(../img/auditui/Icons/done.svg) no-repeat right 1.875rem center;
}

.cursor-pointer {
  cursor: pointer;
}

.form-control {
  border-radius: 0.25rem;
  border: 0.0625rem solid #E9ECEF;
}

.form-group {
  margin-bottom: 1.25rem;
}

.custom-select {
  border-radius: 0.25rem !important;
  color: #8898AA;
  background: #fff url(../img/auditui/Icons/expand_more.svg) no-repeat right 0.75rem center;
  padding: 0.375rem 2.625rem 0.375rem 0.9375rem;
  height: auto !important;
  font-size: 1rem;
  line-height: 1.6875rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input[type=checkbox] {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCIgZmlsbD0iIzFFMUUxRSI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xOSA1djE0SDVWNWgxNG0wLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMlY1YzAtMS4xLS45LTItMi0yeiIvPjwvc3ZnPgo=) no-repeat;
  height: 1.5rem;
  width: 1.5rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

input[type=checkbox]:checked {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCIgZmlsbD0iIzFFMUUxRSI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xOSAzSDVjLTEuMTEgMC0yIC45LTIgMnYxNGMwIDEuMS44OSAyIDIgMmgxNGMxLjExIDAgMi0uOSAyLTJWNWMwLTEuMS0uODktMi0yLTJ6bS05IDE0bC01LTUgMS40MS0xLjQxTDEwIDE0LjE3bDcuNTktNy41OUwxOSA4bC05IDl6Ii8+PC9zdmc+Cg==) no-repeat;
}

.custom-checkbox .custom-control-label {
  background-image: url(../img/auditui/Icons/unchecked.svg);
  background-repeat: no-repeat;
  padding-left: 2.1875rem;
  cursor: pointer;
}

.custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after {
  content: none;
}

.custom-checkbox .custom-control-input {
  height: 1.125rem;
  width: 1.125rem;
  z-index: 1;
  cursor: pointer;
}

.custom-checkbox .custom-control-input ~ .custom-control-label {
  min-height: 1.5rem;
  color: #767676;
  font-size: 1rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label {
  color: #525F7F;
  background-image: url(../img/auditui/Icons/checked.svg);
  min-height: 1.5rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-toggle-slider {
  border: 0.0625rem solid #767676;
  background-color: #767676;
}

.custom-toggle-slider::before {
  height: 0.875rem;
  width: 0.875rem;
  background: #fff;
}

.custom-toggle {
  width: 2.125rem;
  height: 1.25rem;
}

.custom-toggle input:checked + .custom-toggle-slider {
  background-color: #83BA0D;
  border-color: #83BA0D;
}

.custom-toggle input:checked + .custom-toggle-slider:before {
  background: #fff;
  -webkit-transform: translateX(0.875rem);
          transform: translateX(0.875rem);
}

.au-ban.card {
  border-top: 0;
  margin-bottom: 1.25rem;
  -webkit-box-shadow: 0 0.125rem 0.625rem 0 rgba(136, 152, 170, 0.15);
  box-shadow: 0 0.125rem 0.625rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 0.375rem;
}

.au-ban.card .card-body {
  padding: 0.9375rem 1.25rem;
}

.au-ban.card .ban-title {
  font-size: 0.8125rem;
  line-height: 1.25rem;
}

.au-ban.card .ban-value {
  color: #32325D;
}

@media (max-width: 767.98px) {
  .au-ban {
    margin-bottom: 1.25rem;
  }
}

.lbcard-body {
  padding: 1.25rem 1.875rem;
}

@media (max-width: 767.98px) {
  .lbcard-body {
    padding: 1.25rem;
    -webkit-box-shadow: inset 0 -0.0625rem 0 0 #e9ecef;
    box-shadow: inset 0 -0.0625rem 0 0 #e9ecef;
    margin-bottom: 0.625rem;
  }
}

.loss_stats .stats-text {
  padding: 0 0.625rem;
  border: 0;
  color: #32325D;
  font-weight: bold;
  font-size: 0.8125rem;
}

@media (max-width: 767.98px) {
  .loss_stats .stats-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 0.9375rem;
    border-right: 0.0625rem solid #DEE2E6;
  }
  .loss_stats .stats-text:last-of-type {
    border-right: 0;
  }
  .loss_stats .stats-text span {
    font-size: 0.625rem;
  }
}

.dc_nav, .inverter_nav, .ac_nav {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #E9ECEF;
          box-shadow: inset 0 -0.0625rem 0 0 #E9ECEF;
}

@media (max-width: 767.98px) {
  .dc_nav, .inverter_nav, .ac_nav {
    position: relative;
    border: 0.0625rem solid #DEE2E6;
    border-radius: 0.375rem;
    padding-left: 0;
    padding-right: 0;
    margin: 0.625rem;
  }
  .dc_nav .mobile-bottom-nav, .inverter_nav .mobile-bottom-nav, .ac_nav .mobile-bottom-nav {
    padding: 0.6875rem 0.9375rem;
  }
}

.dc_tabs li .nav-link {
  padding: 0.75rem 1.25rem;
  color: #767676;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
  border: 0;
  margin-bottom: 0;
  border-bottom: 0.1875rem solid transparent;
}

.dc_tabs li .nav-link:hover, .dc_tabs li .nav-link.active {
  border: 0;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: #5E72E4;
  background-color: #fff;
  border-bottom: 0.1875rem solid #5E72E4;
}

@media (max-width: 991.98px) {
  .dc_tabs li .nav-link {
    padding: 0.75rem 0.875rem;
  }
  .dc_tabs li .nav-link:hover, .dc_tabs li .nav-link.active {
    padding: 0.75rem 0.875rem;
  }
}

@media (max-width: 767.98px) {
  .dc_tabs {
    position: absolute;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    top: 100%;
    z-index: 1;
    background-color: #fff;
    -webkit-box-shadow: 0 0.1875rem 0.375rem #ddd;
    box-shadow: 0 0.1875rem 0.375rem #ddd;
    left: 0;
    right: 0;
    display: none;
  }
  .dc_tabs.open {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .dc_tabs li {
    width: 100%;
  }
}

.lb-drop {
  border: 0.0625rem solid #ddd;
  border-color: #ddd !important;
  font-size: 1rem;
  line-height: 1.625rem;
  padding: 0.6875rem 0.9375rem;
  border-radius: 0.375rem;
  color: #525f7f;
  font-weight: 400;
}

@media (min-width: 576px) {
  .ticket-drop {
    width: 25rem;
  }
}

@media (max-width: 767.98px) {
  .mobile-tabpane .collapse:not(.show) {
    display: block;
  }
}

.table-body tr td, .table-body tr th {
  padding: .5rem 1rem;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .table-body tr td {
    height: 3.25rem;
  }
}

.table tr th {
  font-weight: bold;
}

.table thead .tablehead-checked th {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  vertical-align: middle;
}

.pagination {
  margin-bottom: 0;
}

.vertical-middle {
  vertical-align: middle !important;
}

.total-row td {
  font-weight: bold;
}

.react-bootstrap-table .order-4::before {
  margin-left: .25rem;
}

.react-bootstrap-table .caret-4-desc::before {
  content: none;
}

.react-bootstrap-table .sortable .order-4::after,
.react-bootstrap-table .sortable .caret-4-asc::after,
.react-bootstrap-table .sortable .caret-4-desc::after {
  content: "";
  background: url(../img/auditui/Icons/ic_sortboth.svg);
  height: 1.5rem;
  width: 1.5rem;
  opacity: 1;
  background-size: 0.6875rem;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  position: absolute;
  bottom: .8em;
  right: auto;
}

.react-bootstrap-table .sortable .caret-4-desc::after {
  background-image: url(../img/auditui/Icons/ic_sortdown.svg);
  background-size: 0.6875rem;
}

.react-bootstrap-table .sortable .caret-4-asc::after {
  background-image: url(../img/auditui/Icons/ic_sortup.svg);
  background-size: 0.6875rem;
}

.react-bootstrap-table .sortable .order-4::before,
.react-bootstrap-table .sortable .caret-4-desc::before,
.react-bootstrap-table .sortable .caret-4-asc::before {
  content: none;
  margin-left: .25rem;
}

.accordion_lb .react-bootstrap-table .table {
  margin-bottom: 0;
  border-bottom: 0;
}

@media (max-width: 991.98px) {
  .accordion_lb .react-bootstrap-table .table th, .accordion_lb .react-bootstrap-table .table td {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 767.98px) {
  .accordion_lb .react-bootstrap-table {
    padding-bottom: 1.25rem;
    padding-top: 0.625rem;
  }
}

.badge {
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-transform: unset;
  font-weight: normal;
  padding: 0.25rem 0.625rem;
}

.badge.badge-success {
  background-color: #83BA0D;
}

.badge.badge-gray {
  background-color: #767676;
}

.selection-cell .custom-control, .selection-cell-header .custom-control {
  padding-left: 0;
}

.selection-cell .custom-checkbox .custom-control-label, .selection-cell-header .custom-checkbox .custom-control-label {
  background-image: url(../img/auditui/Icons/unchecked-dark.svg);
}

.selection-cell .custom-checkbox .custom-control-input:checked ~ .custom-control-label, .selection-cell-header .custom-checkbox .custom-control-input:checked ~ .custom-control-label {
  background-image: url(../img/auditui/Icons/checked-dark.svg);
}

.selection-cell .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label, .selection-cell-header .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label {
  background-image: url(../img/auditui/Icons/checkbox-indeterminate.svg);
}

@media (max-width: 767.98px) {
  .selection-cell, .selection-cell-header {
    display: none !important;
  }
}

.selection-cell input[type="checkbox"] {
  margin-top: 0.375rem;
}

.selection-cell-header input[type="checkbox"] {
  margin-top: 0.1875rem;
}

.ticket-modal-list .row > div {
  font-size: 0.875rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  line-height: 1.6875rem;
}

.ticket-modal-list .row > div:first-child {
  width: 12.25rem;
  padding-left: 0;
}

.ticket-modal-list .row > div:last-child {
  padding-right: 0;
  padding-left: 0;
}

.ticket-modal-body {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-top: 0.625rem;
  padding-bottom: 0.5rem;
}

@media (max-width: 767.98px) {
  .ticket-action-btn {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .responsive-table table, .responsive-table thead, .responsive-table tbody, .responsive-table th, .responsive-table td, .responsive-table tr {
    display: block;
  }
  .responsive-table tr td, .responsive-table tr th {
    min-height: 2.5625rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .responsive-table thead tr {
    position: absolute;
    top: -624.9375rem;
    left: -624.9375rem;
  }
  .responsive-table tr {
    -webkit-box-shadow: inset 0 -0.1875rem 0 0 #DEE2E6;
            box-shadow: inset 0 -0.1875rem 0 0 #DEE2E6;
  }
  .responsive-table tr td {
    border: none;
    border-top: 0.0625rem solid #DEE2E6;
    border-left: 0.0625rem solid #DEE2E6;
    border-right: 0.0625rem solid #DEE2E6;
    position: relative;
    padding-left: calc(50% + 0.625em) !important;
    white-space: normal;
    text-align: left;
    padding-right: 0.625rem;
  }
  .responsive-table tr td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 0.625rem;
    bottom: 0;
    width: calc(50% - 0.625em);
    padding-right: 0.625rem;
    /* white-space: nowrap; */
    text-align: left;
    content: attr(data-title);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-right: 0.0625rem solid #DEE2E6;
    font-weight: bold;
  }
  .responsive-table tr td:first-of-type {
    /* padding-left: 0.625rem !important; */
    color: #5E72E4;
    background: #f1f3f9 !important;
  }
  /* .responsive-table tr td:first-of-type:before {
    content: none;
  } */
  /* .responsive-table tr:nth-child(even) {
    background-color: #fff;
  }
  .responsive-table tr:nth-child(odd) {
    background: #F8F9FE;
  } */
  .responsive-table tr:last-child td {
    font-weight: normal;
    color: #525F7F;
  }
}

.recommendation-content ul li {
  font-size: 1rem;
  line-height: 1.875rem;
}

.recommendation-content p {
  font-weight: normal;
}

@media screen and (max-width: 767px) and (orientation: landscape) {
  .instruction-text {
    display: none !important;
  }
}

@media (max-width: 800px) {
  .navbar-expand .navbar-collapse {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}

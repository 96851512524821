.rmsc {
    --rmsc-main: #4285f4;
    --rmsc-hover: #f1f3f5;
    --rmsc-selected: #e2e6ea;
    --rmsc-border: #ccc;
    --rmsc-gray: #aaa;
    --rmsc-bg: #fff;
    --rmsc-p: 5px!important; /* Spacing */
    --rmsc-radius: 4px; /* Radius */
    --rmsc-h: 16px; /* Height */

    font-size: 12px!important;
}


.rmsc .dropdown-content {
    z-index: 1;
    top: 100%;
    width: 100%;
    align-self: center;
    margin-bottom: 2px!important;
    padding-bottom: 2px!important;
    font-size: 12px!important;
}

.rmsc .item-renderer {
    display: flex;
    margin: 0;
    align-items: center!important;
    height: 16px!important;
}

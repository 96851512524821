.compact-table {
    font-size: 0.875rem;
    white-space: pre-wrap;
    line-height: 1.5;
}

.compact-table thead th {
    white-space: break-spaces;
    padding: 0.12rem;
    padding-left: 0.4rem!important;
    padding-right: 0.4rem!important;

}

.compact-table .text-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.compact-table tbody td {
    padding: 0.10rem;
    /*white-space: break-spaces;*/
    margin: 0px;
    padding-left: 0.4rem!important;
    padding-right: 0.4rem!important;

}

.compact-table tr {
    line-height: 1.5;

}

.sticky-table-container {
    height: 35em; /* Adjust the height to your preference */
    overflow: auto;
}

.sticky-table-header th {
    position: sticky!important;
    top: 0;
    background-color: #f8f9fa;
    z-index: 10;
}
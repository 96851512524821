.legend {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    
}

.legend-item {
    display: flex;
    align-items: center;
    margin-right: 16px;
    border-radius: 4px;
    font-size: 10px;
    padding-top: 7px;
}

.legend-color {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin-right: 2px;
}

 .Available .legend-color {
    background-color: #4caf50;
}

.LostComms .legend-color {
    background-color: #8c8e90;
}
    
.Breakdown .legend-color {
    background-color: #f44336;
}

.Sleep .legend-color {
    background-color: #ff9800;
} 
.stacked-progress-bars {
    display: flex;
    height: 1.5em;
    background-color: #f3f3f3;
    border-radius: 2px;
    margin: 0.4em;
    min-width: 100px;
    overflow: hidden;
}

.progress-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 14px;
    color: white;
    text-align: center;
}

.Ok {
    background-color: #4caf50;
}
.Available {
    background-color: #4caf50;
}

.LostComms {
    background-color: #8c8e90;
}

.Breakdown {
    background-color: #f44336;
}

.Sleep {
    background-color: #ff9800;
}
